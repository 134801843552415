import CustomCheckBox from '@/components/customCheckBox';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercesWrite } from '@/data/commerce/commerce.graphql';
import { InvoiceDuplicate, InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { AgreementsRead } from '@/data/management/agreement.graphql';
import { LineItemsRead } from '@/data/management/lineItem.graphql';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { commercePropertiesToOmit } from '@/pages/dashboard/commerce/invoices/actions/invoiceUtils';
import {
	VerifyHouseAccountInvoicesModal,
} from '@/pages/dashboard/management/houseAccounts/form/modals/verifyHouseAccountInvoices';
import { generateHAInvoice } from '@/pages/dashboard/management/houseAccounts/utils';
import { getClientForMerchant } from '@/pages/tender/[id]/drawersActions/clientHelpers';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal, useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import {
	Client,
	HouseAccount,
	MutationCommercesWriteArgs,
	MutationInvoiceDuplicateArgs,
	MutationInvoiceWriteArgs,
	Order,
	QueryAgreementsReadArgs,
	QueryLineItemsReadArgs,
} from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import { getBrowserTimezone } from '@/utils/timezone';
import { FormControlLabel, Grid, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material';
import { format, set, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isEmpty, keyBy, omit, pick, sortBy, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';

export default function HAMergeOptionsModal( {
	type,
	clearRows,
	selectedInvoices,
	houseAccount,
	month,
	endOfWeek,
}: {
	type: string,
	clearRows: () => void,
	selectedInvoices: Order[],
	houseAccount?: HouseAccount,
	month?: string,
	endOfWeek?: Date
} ) {
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	const router = useRouter();
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const { showModal } = useModal();
	const [ mergeType, setMergeType ] = useState( 'order' );
	const [ addDescription, setAddDescription ] = useState( true );
	const commerceType = toLower( type );
	const timezone = getBrowserTimezone();
	
	const generateInvoice = async ( selectedInvoices: Order[] ) => {
		const location = selectedInvoices.find( ( invoice ) => invoice.companyLocation )?.companyLocation;
		const client = selectedInvoices.find( ( invoice ) => invoice.client )?.client;
		
		const serviceDate = selectedInvoices.find( ( invoice ) => invoice.serviceDate )?.serviceDate;
		
		const invoiceMonth = endOfWeek
			? utcToZonedTime( endOfWeek, timezone ).toISOString()
			: utcToZonedTime( month || serviceDate ? new Date( month || serviceDate ) : new Date(), timezone )
				.toISOString();
		
		if ( !mergeType ) return;
		let mergedInvoice: Order;
		
		try {
			enqueueSnackbar( t( 'common:merge-inprogress' ), { variant: 'info' } );
			if ( mergeType === 'lineItem' ) {
				const invoicesIds = selectedInvoices.map( ( { id } ) => id );
				
				const selectedInvoicesWithIds = keyBy( selectedInvoices, 'id' );
				
				let invoicesWithIndex = selectedInvoices.reduce( ( obj,
					invoice ) => ( { ...obj, [ invoice.id ]: 0 } ), {} as Record<string, number> );
				
				const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
					query    : LineItemsRead,
					variables: { options: { limit: 10000, filter: { order: { $in: invoicesIds } } } },
				} );
				const lineItems = sortBy( lineItemsRead?.items, [ 'order.createdAt' ] );
				
				const invoicesLineItems = lineItems?.map( ( lineItem, index ) => {
					const invoice = selectedInvoicesWithIds[ lineItem.order.id ];
					const date = invoice.type === 'ORDER'
						? invoice.createdAt
						: invoice.serviceDate || invoice.createdAt;
					const desc = `(#${invoice.number} for ${safeFormatInTimeZone( date || new Date(), 'PP' )})`;
					if ( !invoicesWithIndex[ lineItem.order.id ] ) {
						invoicesWithIndex = { ...invoicesWithIndex, [ lineItem.order.id ]: 1 };
					}
					
					return {
						number     : invoice.metadata?.customNumber || invoice.externalId || invoice.number,
						date,
						sequence   : index,
						description: invoicesWithIndex[ lineItem.order.id ] === 1 ? `${desc} ${invoice.po
							? `PO: ${invoice.po}`
							: ''} ${invoice.notes ? `Note: ${invoice.notes}` : ''}` : desc,
						lineItem,
					};
				} );
				
				// invoicesLineItems = sortBy( invoicesLineItems, [ ( l ) => l.lineItem.sequence ], [ ( l ) => l.lineItem.name ], [ ( l ) => l.lineItem.price ] );
				
				const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
					query    : AgreementsRead,
					variables: { options: { limit: 2000, filter: { order: { $in: invoicesIds } } } },
				} );
				const agreements = agreementsRead?.items;
				
				const { invoiceDuplicate } = await mutateGraphQL<MutationInvoiceDuplicateArgs>( {
					mutation : InvoiceDuplicate,
					variables: { ids: invoicesIds },
				} );
				
				let merchantClient: Client;
				if ( client && location ) {
					merchantClient = await getClientForMerchant( {
						companyLocation: location,
					}, client, staff );
				}
				
				let selectedMonth: Date;
				if ( invoiceMonth ) {
					const date = new Date();
					selectedMonth = utcToZonedTime( set( subDays( new Date( invoiceMonth ), 1 ), {
						hours  : date.getHours(),
						minutes: date.getMinutes(),
					} ), timezone );
				}
				
				const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : InvoiceWrite,
					variables: {
						id          : invoiceDuplicate.id,
						customNumber: Boolean( staff.company.metadata?.customNumber ),
						method      : 'Merge Account Invoice',
						input       : {
							gateway        : null,
							client         : merchantClient?.id || null,
							companyLocation: location?.id || null,
							serviceDate    : selectedMonth
								? selectedMonth
								: selectedInvoices[ 0 ].serviceDate || selectedInvoices[ 0 ].updatedAt,
							type           : 'ACCOUNT',
							standingDate   : new Date(),
							houseAccount   : houseAccount?.id || selectedInvoices[ 0 ].houseAccount?.id || null,
							metadata       : {
								...omit( invoiceDuplicate.metadata, commercePropertiesToOmit ),
								enableCashDiscount: staff.company.metadata?.cashDiscount > 0,
								accountInvoiceDate: selectedMonth ? selectedMonth : selectedInvoices[ 0 ].updatedAt,
								documentLayout    : {
									color         : {
										dark : '#424074',
										light: '#b1adf4',
									},
									template      : 'default',
									lineItemLayout: 'industrial',
								},
								signatureLine     : staff.company.metadata?.signatureLine,
								sendReminder      : staff.company.metadata?.dueReminder,
								mergedSignatures  : selectedInvoices?.reduce( ( obj, commerce ) => {
									if ( commerce.metadata?.signature ) {
										obj = {
											...obj,
											[ commerce.number || commerce.externalId ]: commerce.metadata.signature || '',
										};
									}
									return obj;
								}, {} ),
							},
							notes          : null,
							po             : null,
							lineItems      : invoicesLineItems?.map( ( item ) => {
								const lineItem = item.lineItem;
								const desc = addDescription
									? `${lineItem.description || ''}
												${item.description}`
									: null;
								return {
									...pick( lineItem, [
										'name',
										'price',
										'image',
										'unit',
										'quantity',
										'tax',
										'metadata',
										'orderTax',
									] ),
									sequence      : item.sequence,
									isRevenue     : false,
									description   : desc?.trim(),
									modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
									prices        : lineItem.prices?.map( ( price ) =>
										pick( price, [
											'name',
											'isPercent',
											'value',
											'quantity',
											'metadata',
											'externalId',
										] ) ),
									uom           : lineItem.uom?.id || null,
									item          : lineItem.item?.id || null,
									category      : lineItem.category?.id || null,
								};
							} ),
							agreements     : agreements?.map( ( agreement ) => ( {
								...pick( agreement, [
									'title',
									'body',
									'requireSignature',
									'expiration',
								] ),
								company: agreement.company.id,
							} ) ),
							prices         : !isEmpty( invoiceDuplicate.prices )
								? invoiceDuplicate.prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
									.map( ( { id } ) => ( { id } ) )
								: [],
						},
					},
				} );
				mergedInvoice = invoiceWrite;
				
				await mutateGraphQL<MutationCommercesWriteArgs>( {
					mutation : CommercesWrite,
					variables: {
						inputs: selectedInvoices.map( ( commerce ) => ( {
							id      : validate( commerce.id ) ? commerce.id : undefined,
							merged  : true,
							metadata: { mergedTo: invoiceDuplicate.id },
						} ) ),
					},
				} );
				
			} else {
				
				// generate order merge invoice for HA
				enqueueSnackbar( 'Please wait while the invoice is being generated...', { variant: 'info' } );
				mergedInvoice = await generateHAInvoice( selectedInvoices, staff, selectedInvoices[ 0 ].houseAccount, format( serviceDate || new Date(), 'MMMM yyyy' ), selectedInvoices[ 0 ].client as Client, location, true );
			}
			
			if ( location?.gateway || mergedInvoice.companyLocation?.gateway ) {
				await postCloverMeteredBilling( {
					orderId    : mergedInvoice.id,
					gatewayId  : location?.gateway?.id || mergedInvoice.companyLocation?.gateway?.id,
					eventType  : 'HA Merged',
					key        : 'mergeHA',
					staffId    : staff?.id,
					clientId   : mergedInvoice.client?.id || null,
					entityCount: selectedInvoices.length || 1,
				} ).catch( () => null );
			}
			
			await router.push( `/dashboard/commerce/invoices/${mergedInvoice.id}${mergeType === 'lineItem'
				? '/edit'
				: ''}` );
			
			clearRows();
			closeModal();
		} catch ( e ) {
			console.log( 'error', e );
		}
		
	};
	
	return (
		<ResponsiveModalContainer
			title='Merge Invoices for House Account'
			saveButtonProps={{
				disabled: !mergeType,
			}}
			onClose={() => {
				setMergeType( '' );
				closeModal();
			}}
			onSave={async () => {
				const invoicesWithMergedTo = selectedInvoices.filter( ( invoice ) => invoice.metadata?.mergedTo );
				if ( !isEmpty( invoicesWithMergedTo ) ) {
					showModal( VerifyHouseAccountInvoicesModal, { maxWidth: 'sm' }, {
						invoicesWithMergedTo,
						onSubmit: async () => await generateInvoice( selectedInvoices ),
					} );
				} else {
					await generateInvoice( selectedInvoices );
				}
			}}>
			<Grid
				container
				spacing={1}
				sx={{
					'.MuiListItemButton-root': {
						p      : 2,
						display: 'block',
						height : { xs: '100%', sm: 250 },
						
					},
				}}>
				<Grid item xs={12} sm={6}>
					<ListItemButton
						disableGutters
						sx={{
							border      : 4,
							borderRadius: 3,
							borderColor : mergeType === 'order' ? 'primary.main' : 'divider',
						}}
						onClick={() => setMergeType( 'order' )}>
						<Typography variant='h5' mb={2}>{`Merge ${type}s (Recommended)`}</Typography>
						<ListItemText sx={{ color: 'text.secondary' }}>
							{`${type} ${t( 'common:merge-option-des-one' )} ${commerceType} ${t( 'common:merge-option-des-two' )}
							${t( 'common:merge-option-des-three' )} ${commerceType} ${t( 'common:merge-option-des-four' )} ${commerceType} ${t( 'common:merge-option-des-five' )}
							${t( 'common:merge-option-des-six' )} ${commerceType} ${t( 'common:merge-option-des-seven' )} ${commerceType}s.`}
						</ListItemText>
					</ListItemButton>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ListItemButton
						sx={{
							border      : 4,
							borderRadius: 3,
							borderColor : mergeType === 'lineItem' ? 'primary.main' : 'divider',
						}}
						onClick={() => setMergeType( 'lineItem' )}>
						<Typography variant='h5' mb={2}>{t( 'common:merge-line-items' )}</Typography>
						<ListItemText sx={{ color: 'text.secondary' }}>
							{`${t( 'common:line-merge-des-one' )} ${commerceType} ${t( 'common:line-merge-des-two' )}
							${t( 'common:line-merge-des-three' )} ${commerceType}.
							${t( 'common:line-merge-des-four' )} ${commerceType} ${t( 'common:line-merge-des-five' )}`}
						
						</ListItemText>
						<Tooltip title={t( 'common:line-merge-desription' )}>
							<FormControlLabel
								label={t( 'common:line-merge-des-tooltip' )}
								control={(
									<CustomCheckBox
										checked={addDescription}
										sx={{ ml: 1 }}
										onChange={( e, checked ) => setAddDescription( checked )}
									/>
								)}
							/>
						</Tooltip>
					</ListItemButton>
				</Grid>
			</Grid>
		</ResponsiveModalContainer>
	);
}
