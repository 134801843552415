import GraphqlTable from '@/components/graphqlTable';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Order, QueryInvoicesReadArgs } from '@/types/schema';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { InvoicesRead } from '../../../../data/commerce/invoice.graphql';
import MobileRowPanel from '../components/mobileRow';
import useDeletedInvoiceActions from './actions/deletedTableActions';
import DeleteInvoicesMultiActions from './actions/deleteInvoicesMultiActions';
import RowPanel from './rowPanel';
import { columns } from './table';

export default function DeletedInvoicesModal() {
	const { staff } = useUserInfo();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	
	return (
		<ResponsiveModalContainer
			title={t( 'commerce:deleted-invoices' )}
			secondaryTitle={t( 'commerce:recover-your-deleted-invoices' )}
			saveButtonText={t( 'common:done' )}
			onSave={async () => await queryClient.invalidateQueries( [ 'order' ] )}>
			<GraphqlTable<Order, QueryInvoicesReadArgs>
				disableUrlSync
				showFooter
				searchable
				queryKey='invoice'
				query={InvoicesRead}
				subscription={{ INVOICE: staff.company.id }}
				columns={() => columns( 'deletedAt' )}
				variables={{
					options: {
						filter: {
							staff    : isOwner || isAdmin ? { $ne: null } : staff.id,
							deletedAt: { $ne: null },
						},
					},
				}}
				useActions={useDeletedInvoiceActions}
				initialState={{
					hiddenColumns: [
						'id',
						'taxTotal',
						'updatedAt',
						'client.id',
						'metadata.signature',
						'houseAccount.id',
						'houseAccount',
						'po',
						'payments',
						'due',
						'type' ],
				}}
				expandedComponent={( row ) => <RowPanel hideTags row={row}/>}
				mobileRenderRow={( invoice ) => <MobileRowPanel data={invoice as any}/>}
				renderMultiActions={( invoices, clearRows, selectedRows ) => (
					<DeleteInvoicesMultiActions
						clearRows={clearRows}
						selectedInvoices={selectedRows}
					/>
				)}
			/>
		</ResponsiveModalContainer>
	);
}
