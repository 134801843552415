import { ActionProps } from '@/components/actions';
import { axiosClient } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { InvoiceWrite } from '@/data/commerce/invoice.graphql';
import isOrderSyncedToClover from '@/helpers/useCheckIsSyncedToClover';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { MutationInvoiceWriteArgs, Order } from '@/types/schema';
import { SettingsBackupRestore as SettingsBackupRestoreIcon } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function useDeletedInvoiceActions( invoice: Order ) {
	const editable = usePermissions( permissions.invoices.write );
	const confirmDialog = useConfirmDialog();
	const queryClient = useQueryClient();
	const syncedToClover = isOrderSyncedToClover( invoice );
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	
	return [
		editable && {
			name   : t( 'common:restore' ),
			icon   : <SettingsBackupRestoreIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'common:recover' ),
					message: t( 'common:are-you-sure-you-want-to-recover' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : InvoiceWrite,
					variables: {
						id    : invoice.id,
						method: 'Recovered',
						input : { deletedAt: null },
					},
				} );
				if ( syncedToClover ) {
					await axios
						.post( `${process.env.NEXT_PUBLIC_SITE_URL}/api/processor/manage/importOrder`, {
							id     : invoice.id,
							staffId: staff?.id,
						} )
						.catch( () => null );
				}
				await queryClient.invalidateQueries( [ 'order' ] );
			},
		},
	] as ActionProps[];
}
