import { encodeFilters } from '@/pages/dashboard/commerce/invoices/actions/invoiceUtils';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import { useEvents } from '@/providers/event';
import { getBrowserTimezone } from '@/utils/timezone';
import { endOfDay, endOfMonth, startOfDay, startOfMonth, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

export default function useInvoicesQuickFilterActions() {
	const { t } = useTranslation();
	const creatable = usePermissions( permissions.invoices.write );
	const router = useRouter();
	const event = useEvents();
	const timeZone = getBrowserTimezone();
	
	const date = timeZone ? utcToZonedTime( new Date(), timeZone ) : new Date();
	const getTimePeriodFilter = ( column, startDate, endDate ) => [ {
		id   : column,
		value: {
			temp: {
				checked              : true,
				operatorValue        : 'range',
				startDate            : startDate,
				endDate              : endDate,
				dayMonthSelectorValue: 'days',
				dayMonthValue        : '',
			},
			$gte: startDate,
			$lte: endDate,
		},
	} ];
	
	return [ {
		name   : 'Today\'s Invoices',
		onClick: () => {
			router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					s: encodeFilters( { filters: getTimePeriodFilter( 'createdAt', startOfDay( date ), endOfDay( date ) ) } ),
				},
			}, undefined, { shallow: true } ).then();
			event.emit( 'url.filter', true );
		},
	}, {
		name   : 'Yesterday\'s Invoices',
		onClick: () => {
			const yesterdayDate = subDays( date, 1 );
			router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					s: encodeFilters( { filters: getTimePeriodFilter( 'createdAt', startOfDay( yesterdayDate ), endOfDay( yesterdayDate ) ) } ),
				},
			}, undefined, { shallow: true } ).then();
			event.emit( 'url.filter', true );
		},
	}, {
		name   : 'This Month\'s Invoices',
		onClick: () => {
			router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					s: encodeFilters( { filters: getTimePeriodFilter( 'createdAt', startOfMonth( date ), endOfMonth( date ) ) } ),
				},
			}, undefined, { shallow: true } ).then();
			event.emit( 'url.filter', true );
		},
	}, creatable && {
		name   : t( 'commerce:all-open-invoice' ),
		onClick: () => {
			router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					s: encodeFilters( {
						filters: [ {
							id   : 'status',
							value: {
								$in : [ 'SENT', 'VIEWED', 'PARTIALLY_PAID' ],
								temp: { checked: true },
							},
						} ],
					} ),
				},
			}, undefined, { shallow: true } ).then();
			event.emit( 'url.filter', true );
		},
	}, {
		name   : t( 'commerce:recurring-invoice' ),
		onClick: () => {
			router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					s: encodeFilters( {
						filters: [ {
							id   : 'status',
							value: { $eq: 'STANDING', temp: { checked: true } },
						} ],
					} ),
				},
			}, undefined, { shallow: true } ).then();
			event.emit( 'url.filter', true );
		},
	}, {
		name   : t( 'commerce:view-house-account-orders' ),
		onClick: () => {
			router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					s: encodeFilters( {
						filters: [ {
							id   : 'status',
							value: { $eq: 'ACCOUNT', temp: { checked: true } },
						} ],
					} ),
				},
			}, undefined, { shallow: true } ).then();
			event.emit( 'url.filter', true );
		},
	}, {
		name   : t( 'common:view-house-invoice' ),
		onClick: () => {
			router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					s: encodeFilters( { filters: [ { id: 'type', value: { $eq: 'ACCOUNT', temp: { checked: true } } } ] } ),
				},
			}, undefined, { shallow: true } ).then();
			event.emit( 'url.filter', true );
		},
	} ];
}

