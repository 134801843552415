import Actions from '@/components/actions';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import useWriteLog from '@/pages/dashboard/commerce/components/utils';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Order } from '@/types/schema';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { type Theme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';

export default function DeleteInvoicesMultiActions( {
	clearRows,
	selectedInvoices,
}: {
	clearRows: () => void,
	selectedInvoices: Order[]
} ) {
	const { isCloverDevice } = useGetDeviceInfo();
	const { user } = useUserInfo();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isIpad = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'lg' ) );
	const confirmDialog = useConfirmDialog();
	const { enqueueSnackbar } = useSnackbar();
	
	const invoiceWithCloverGateway = selectedInvoices[ 0 ]?.gateway?.external === 'CLOVER'
		? selectedInvoices[ 0 ]
		: null;
	const sameGateway = selectedInvoices.every( ( invoice ) => invoice.gateway?.id === invoiceWithCloverGateway.gateway?.id && invoice.externalId );
	const externalIds = selectedInvoices.map( ( invoice ) => invoice.externalId );
	
	const noExternalIds = isEmpty( externalIds );
	const logWrite = useWriteLog();
	
	return (
		<Actions
			separated
			max={isMobile ? 1 : isCloverDevice || isIpad ? 4 : 0}
			items={[ {
				name    : 'Delete from Clover',
				icon    : <DeleteIcon/>,
				details : !sameGateway ? 'Select invoices for the same Clover location'
					: noExternalIds ? 'The selected invoices aren\'t synced to Clover' : '',
				disabled: !sameGateway || noExternalIds,
				onClick : async () => {
					const value = await confirmDialog( {
						title  : 'Delete from Clover',
						message: `Are you sure you want to delete ${selectedInvoices.length > 0
							? 'these invoices'
							: 'this invoice'} from Clover as well?`,
					} );
					if ( !value ) return;
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrders`, {
						externalIds,
						gatewayId: invoiceWithCloverGateway?.gateway?.id,
					} );
					await logWrite( {
						company   : selectedInvoices[ 0 ].company.id,
						name      : 'DELETED',
						method    : 'Deleted Invoices From Clover',
						documentId: selectedInvoices[ 0 ].id,
						user      : user?.id,
						table     : 'INVOICE',
					} );
					enqueueSnackbar( 'Invoices deleted successfully', { variant: 'success' } );
					clearRows();
				},
				
			} ]}
		/>
	);
	
}
