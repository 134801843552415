import { mutateGraphQL } from '@/data/apollo';
import { LineItemWrite } from '@/data/management/lineItem.graphql';
import { CompanyTagsType } from '@/modals/companyTags';
import LineItemTagChip from '@/pages/dashboard/commerce/chips/lineItemTagChip';
import { useMenu } from '@/providers/menu';
import type { Company, LineItem, MutationLineItemWriteArgs } from '@/types/schema';
import { MoreHoriz as MoreHorizIcon, SettingsSuggest as SettingsSuggestIcon } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, MenuItem, MenuList, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Tags( {
	lineItem,
	hideTags,
	company,
	setTag,
}: {
	lineItem: LineItem,
	hideTags?: boolean,
	company: Company,
	setTag?: ( tag: string ) => void
} ) {
	const { t } = useTranslation();
	const { showMenu } = useMenu();
	const [ loading, setLoading ] = useState( false );
	const lineItemTags: CompanyTagsType[] = company?.tags?.lineItemTags?.filter( ( tag ) => tag?.forOrder ) || [];
	const lineItemsTagsNames = lineItemTags?.map( ( tag ) => tag.name ) || [];
	const queryClient = useQueryClient();
	
	if ( hideTags ) return null;
	
	return (
		<Stack direction='row' spacing={1} alignItems='center'>
			<LineItemTagChip status={lineItem.status} company={company} loading={loading}/>
			<IconButton
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<MenuList sx={{ p: 0 }}>
						{!isEmpty( lineItemsTagsNames ) ? lineItemsTagsNames.map( ( status, index ) => (
							<MenuItem
								key={index}
								sx={{ my: 0 }}
								selected={lineItem.status === status}
								onClick={async () => {
									try {
										setLoading( true );
										if ( lineItem.id ) {
											await mutateGraphQL<MutationLineItemWriteArgs>( {
												mutation : LineItemWrite,
												variables: {
													id   : lineItem.id,
													input: { status: lineItem.status === status ? null : status },
												},
											} );
										}
										setTag?.( status );
										closeMenu();
										await queryClient.invalidateQueries( [ 'lineItems' ] );
										setLoading( false );
									} catch ( e ) {
										console.log( e );
									} finally {
										setLoading( false );
									}
								}}>
								{status}
							</MenuItem>
						) ) : null}
						<Divider sx={{ my: 1 }}/>
						<MenuItem
							sx={{ my: 0 }}
							onClick={async () => {
								window.open( '/dashboard/settings/doc?tab=general', '_blank' );
								closeMenu();
							}}>
							<ListItemIcon sx={{ width: 30 }}>
								<SettingsSuggestIcon/>
							</ListItemIcon>
							{t( 'settings:create-or-edit-tags' )}
						</MenuItem>
					</MenuList>
				), e.currentTarget )}>
				<MoreHorizIcon/>
			</IconButton>
		</Stack>
	);
}
