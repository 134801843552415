import SubscriptionModal from '@/baseline/subscription';
import UpgradeIcon from '@/baseline/subscription/upgradeIcon';
import useTierPermission from '@/baseline/subscription/useTierPermission';
import { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import StyledImage from '@/components/styledImage';
import { axiosClient, TEMP_HARDCODED_ERROR, useGraphQL } from '@/data';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { EstimateWrite } from '@/data/commerce/estimate.graphql';
import { InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { DevicesRead } from '@/data/device.graphql';
import SyncToClover from '@/helpers/syncToClover';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import HistoryCommerces from '@/modals/historyCommerces';
import QbInvoiceTax from '@/modals/qbInvoiceTax';
import RecurringModal from '@/modals/recurring';
import SchedulePaymentsModal from '@/modals/schedulePaymentsModal';
import SyncWithHouseAccount from '@/modals/syncWithHouseAccount';
import TextMessage from '@/modals/textMessage';
import { convertOrder, getInvoices } from '@/pages/api/processor/manage/quickbooks';
import useGetTransactionStatus from '@/pages/dashboard/commerce/components/getTransactionStatusUtil';
import CommerceItemsUpdating from '@/pages/dashboard/commerce/invoices/commerceItemsUpdating';
import { syncHAInvoiceAndPay } from '@/pages/dashboard/commerce/invoices/syncAndPay';
import TerminalDevicePaymentModal from '@/pages/dashboard/commerce/invoices/terminalDevicePaymentModal';
import generatePaymentLink from '@/pages/dashboard/commerce/payments/form/utils';
import DeviceLoadingModal from '@/pages/dashboard/settings/devices/deviceLoadingModal';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModal } from '@/providers/modal';
import {
	Client,
	CommerceType,
	MutationEstimateWriteArgs,
	MutationInvoiceWriteArgs,
	MutationQbInvoicesSyncArgs,
	Order,
	QueryDevicesReadArgs,
	QueryGatewaysReadArgs,
	QueryLineItemsReadArgs,
} from '@/types/schema';
import { chatSettingsAtom } from '@/utils/atoms';
import { gql } from '@apollo/client';
import {
	AddCircle as AddCircleIcon,
	Archive as ArchiveIcon,
	ChangeCircle as ChangeCircleIcon,
	ChangeCircle as CreateOrderIcon,
	ContentCopy as ContentCopyIcon,
	CopyAll as CopyAllIcon,
	CreditScore as CreditScoreIcon,
	Edit as EditIcon,
	EventRepeat as EventRepeatIcon,
	FindInPage as FindInPageIcon,
	LocalOffer as LocalOfferIcon,
	MoreVert as MoreVertIcon,
	OtherHousesRounded as OtherHousesRoundedIcon,
	Payment as PaymentIcon,
	PointOfSale as PointOfSaleIcon,
	Share as ShareIcon,
	Sms as SmsIcon,
	SyncProblem as SyncProblemIcon,
	TransformRounded as TransformRoundedIcon,
} from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { useSetAtom } from 'jotai';
import { isEmpty, toLower, toUpper } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import process from 'process';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generateHAInvoice } from '../../../management/houseAccounts/utils';
import CloverDeviceSelectionModal from '../../../settings/devices/cloverDeviceSelectionModal';
import SignAndTagModal from '../../components/signAndTagModal';
import { stayOpen } from '../../components/tableHelpers';
import { generateRecurring } from '../../orders/orderGQL';
import { updateStock } from '../../orders/utils';
import PaymentDrawer from '../../payment/paymentDrawer';
import { usePartialInvoiceTableActions } from './drawerActions';
import { LineItemsFetch, makeInvoiceCopy } from './invoiceUtils';
import MarkStatusActions from './markStatusActions';
import { MoreActions, RecurringActions } from './moreActions';

const estimateTypes = [ 'estimate', 'proposal', 'quote', 'work_order', 'bid' ];

export default function useInvoiceActions( invoice: Order, single?: boolean, noPay?: boolean ) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { showModal } = useModal();
	const router = useRouter();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const confirmDialog = useConfirmDialog();
	const { isCloverDevice } = useGetDeviceInfo();
	const event = useEvents();
	const editable = usePermissions( permissions.invoices.write );
	const payable = usePermissions( permissions.payments.read || permissions.payments.write );
	const { staff, user } = useUserInfo();
	const { company } = useCompany();
	const { t } = useTranslation();
	const getTransactionStatus = useGetTransactionStatus();
	const invoiceActions = usePartialInvoiceTableActions();
	const textMessageSubscriptionIsValid = useTierPermission( 'INVOICE_TEXT' );
	const syncToQuickbooksSubscriptionIsValid = useTierPermission( 'QB_SYNC' );
	const deviceSubscriptionIsValid = useTierPermission( 'DEVICE_SUPPORT' );
	const setChatSettings = useSetAtom( chatSettingsAtom );
	const disableEditIfPaid = invoice.status === 'PAID' && staff?.disableActions?.commerces?.disableEditPaid;
	
	const paidByInvoissTender = invoice.payments?.find( ( payment ) => [
		'invoiss',
		'haps',
	].includes( toLower( payment?.type ) ) );
	
	const { data } = useGraphQL<QueryDevicesReadArgs>( {
		queryKey : 'devicesRead',
		query    : DevicesRead,
		variables: {
			options: {
				limit : 30,
				filter: {
					apiKey: { $ne: null },
				},
			},
		},
	}, { enabled: Boolean( invoice.id ) } );
	
	let devices = data?.devicesRead?.items;
	const removeSchedulePayment = invoice.externalId && invoice.status === 'PARTIALLY_PAID';
	
	if ( devices?.length > 0 ) {
		// Filter the devices list to include only those devices that contain the specified staff
		const staffDevices = devices?.filter( ( device ) => device?.staffs?.some( ( s ) => s?.id === staff.id ) );
		if ( staffDevices?.length > 0 ) {
			devices = staffDevices;
		}
	}
	
	const squareConnected = company?.gateways?.some( ( gateway ) => gateway.active && gateway.external === 'SQUARE' );
	const qbconnect = company?.metadata?.qbConnect;
	const notFullyPaidAndHouseAccount = invoice?.paidTotal < invoice?.grandTotal && invoice.houseAccount?.id;
	const accountStatus = invoice.status === 'ACCOUNT';
	const cardConnectPayment = invoice?.payments?.find( ( payment ) => Boolean( payment.cardConnectId ) );
	
	useEffect( () => {
		if ( !single ) return;
		if ( editable && invoice.standing && !invoice.standingActive ) {
			showModal( RecurringModal, { maxWidth: 'sm', fullPageModal: true }, {
				formOrder: invoice,
			} );
		}
	}, [] );
	
	const HouseAccountActions = [
		notFullyPaidAndHouseAccount && accountStatus && {
			name   : t( 'commerce:mark-as-paid-on-clover' ),
			icon   : <CreditScoreIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'commerce:mark-as-paid-on-clover' ),
					message: t( 'commerce:you-want-to-mark-paid-on-clover' ),
				} );
				if ( !value ) return;
				
				const gateway = invoice.gateway || invoice.companyLocation?.gateway;
				const cloverGateway = gateway?.external === 'CLOVER';
				
				if ( !cloverGateway ) throw 'No Clover Gateway Found';
				
				try {
					await axios.post(
						'/api/processor/payment/createPayment',
						{
							type            : 'Invoiss',
							amount          : invoice?.grandTotal - ( invoice.paidTotal || 0 ),
							note            : '',
							orderId         : invoice.id,
							gatewayId       : gateway.id,
							tip             : 0,
							companyId       : invoice.company.id,
							metadata        : invoice.company?.metadata,
							staffId         : staff?.id,
							payerId         : staff?.id,
							payerName       : user?.firstName,
							orderLogMethod  : 'Mark as Paid on Clover',
							paymentLogMethod: 'Made Payment on Clover',
						},
					);
					
					enqueueSnackbar( t( 'commerce:mark-paid-on-clover-successfully' ), { variant: 'success' } );
					
				} catch ( e ) {
					const cloverErrors = e?.response.data?.cloverErrors;
					if ( cloverErrors ) {
						throw (
							cloverErrors?.error?.message
							|| cloverErrors?.message
							|| 'An error has occurred. Clover.com'
						);
					} else {
						enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
					}
				}
			},
			
		},
		notFullyPaidAndHouseAccount && accountStatus && {
			name   : t( 'commerce:mark-as-paid-on-clover-and-invoiss' ),
			icon   : <CreditScoreIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'commerce:mark-as-paid-on-clover-and-invoiss' ),
					message: t( 'commerce:you-want-to-mark-paid-on-clover-and-invoiss' ),
				} );
				if ( !value ) return;
				
				const gateway = invoice.gateway || invoice.companyLocation?.gateway;
				const cloverGateway = gateway?.external === 'CLOVER';
				
				if ( !cloverGateway ) throw 'No Clover Gateway Found';
				
				try {
					await axios.post(
						'/api/processor/payment/createPayment',
						{
							type            : 'Invoiss',
							amount          : ( invoice?.grandTotal || 0 ) - ( invoice.paidTotal || 0 ),
							note            : '',
							orderId         : invoice.id,
							gatewayId       : gateway.id,
							tip             : 0,
							companyId       : invoice.company.id,
							metadata        : invoice.company?.metadata,
							staffId         : staff?.id,
							payerId         : staff?.id,
							payerName       : user?.firstName,
							orderLogMethod  : 'Mark as Paid on Clover and Invoiss',
							paymentLogMethod: 'Made Payment on Clover and Invoiss',
							invoicePaid     : true,
						},
					);
					
					enqueueSnackbar( 'Payment was successful', { variant: 'success' } );
					
				} catch ( e ) {
					const cloverErrors = e?.response.data?.cloverErrors;
					if ( cloverErrors ) {
						throw (
							cloverErrors?.error?.message
							|| cloverErrors?.message
							|| 'An error has occurred. Clover.com'
						);
					} else {
						enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
					}
				}
			},
			
		},
		paidByInvoissTender && {
			name   : invoice.invoicePaid ? t( 'common:mark-as-unpaid' ) : t( 'common:mark-paid' ),
			icon   : <CreditScoreIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title: invoice.invoicePaid
						? t( 'common:mark-as-unpaid' )
						: t( 'common:mark-paid' ),
					message: invoice.invoicePaid
						? t( 'commerce:mark-this-invoice-as-unpaid' )
						: t( 'commerce:mark-this-invoice-as-paid' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : InvoiceWrite,
					variables: {
						id    : invoice.id,
						method: invoice.invoicePaid ? 'Mark as UnPaid' : 'Mark as Paid',
						input : {
							invoicePaid : !invoice.invoicePaid,
							invoiceTotal: invoice.payments.reduce( ( sum, payment ) => sum + payment.amount, 0 ),
						},
					},
				} );
			},
			
		},
		invoice.status !== 'ACCOUNT' && invoice.type === 'INVOICE' && invoice.houseAccount && ![ 'PAID',
		                                                                                         'CANCELLED',
		                                                                                         'VOIDED' ].includes( invoice.status ) && {
			name   : 'Charge to House Account',
			icon   : <PaymentIcon/>,
			onClick: () => {
				showModal( SyncWithHouseAccount, { maxWidth: 'sm' }, {
					onSubmit: async ( action ) => {
						if ( !action ) return;
						await syncHAInvoiceAndPay( invoice, invoice.houseAccount!, action, staff!, user, router, enqueueSnackbar, t );
					},
					invoice,
					onlyHouseAccountOptions: true,
				} );
			},
		},
		accountStatus && editable && !invoice.metadata?.mergedTo && {
			name   : `${t( 'common:generate' )} ${t( 'common:invoice' )}`,
			icon   : <AddCircleIcon/>,
			onClick: async () => {
				enqueueSnackbar( t( 'management:invoice-generating-snackbar' ), { variant: 'info' } );
				const cloverCommerce = await generateHAInvoice( [ invoice ], staff, invoice.houseAccount, format( invoice.serviceDate || new Date(), 'MMMM yyyy' ), invoice.client as Client || invoice.houseAccount?.client as Client, invoice.companyLocation );
				await router.push( `/dashboard/commerce/invoices/${cloverCommerce.id}` );
				
				event.emit( 'reload.graphqlQuery', true );
				
			},
		},
		invoice?.houseAccount?.id && {
			name   : t( 'common:view-all-order' ),
			icon   : <FindInPageIcon/>,
			onClick: () => {
				window.open( `/dashboard/commerce/invoices?s=${encodeURIComponent( btoa( JSON.stringify( {
					filters: [ {
						id   : 'houseAccount.id',
						value: {
							$eq : invoice?.houseAccount?.id,
							temp: { checked: true },
						},
					}, {
						id   : 'type',
						value: {
							$eq : 'INVOICE',
							temp: { checked: true },
						},
					} ].filter( Boolean ),
				} ) ) )}`, '_blank' );
			},
		},
		accountStatus && {
			name   : t( 'commerce:view-generated-invoices' ),
			icon   : <FindInPageIcon/>,
			onClick: () => {
				window.open( `${process.env.NEXT_PUBLIC_SITE_URL}/dashboard/commerce/invoices?s=${encodeURIComponent( btoa( JSON.stringify( {
					filters: [ {
						id   : 'houseAccount.id',
						value: {
							$eq : invoice?.houseAccount?.id,
							temp: { checked: true },
						},
					}, {
						id   : 'type',
						value: {
							$eq : 'ACCOUNT',
							temp: { checked: true },
						},
					} ].filter( Boolean ),
				} ) ) )}`, '_blank' );
			},
		} ].filter( Boolean );
	
	const ConvertActions = [ {
		name   : `${t( 'commerce:convert-to' )} ${t( 'common:order' )}`,
		details: t( 'commerce:turn-order' ),
		icon   : <CreateOrderIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( 'commerce:turn-order' ),
				message: t( 'commerce:confirm-invoice-order' ),
			} );
			if ( !value ) return;
			try {
				await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : InvoiceWrite,
					variables: {
						id    : invoice.id,
						method: 'Convert to Order',
						input : {
							type: 'ORDER',
						},
					},
				} );
				await router.push( `/dashboard/commerce/orders/${invoice.id}/edit` );
			} catch ( e ) {
				throw e;
			}
		},
	}, ...estimateTypes.map( ( type ) => ( {
		name   : `${t( 'common:create' )} ${t( `common:${type}` )}`,
		details: t( `commerce:turn-${type}` ),
		icon   : <CreateOrderIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( `commerce:confirm-${type}-invoice` ),
				message: t( `commerce:success-${type}` ),
			} );
			if ( !value ) return;
			try {
				await mutateGraphQL<MutationEstimateWriteArgs>( {
					mutation : EstimateWrite,
					variables: {
						id          : invoice.id,
						method      : `Turned into ${type}`,
						customNumber: Boolean( company?.metadata?.customNumber ),
						input       : {
							type     : toUpper( type ) as CommerceType,
							staff    : staff?.id,
							createdAt: new Date(),
						},
					},
				} );
				
				await router.push( `/dashboard/commerce/estimates/${invoice.id}/edit` );
			} catch ( e ) {
				throw e;
			}
		},
	} ) ) ];
	
	return [
		editable && !isCloverDevice && {
			name       : t( 'common:edit' ),
			icon       : <EditIcon/>,
			details    : disableEditIfPaid ? 'You do not have permission to edit this paid invoice.' : '',
			buttonProps: { disabled: disableEditIfPaid },
			props      : {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/invoices/${invoice.id}/edit`,
			},
		},
		isCloverDevice && invoice.externalId && invoice.gateway?.external === 'CLOVER' && invoice.status !== 'PAID' && invoice.status !== 'ACCOUNT' && {
			name   : t( 'commerce:edit-on-clover' ),
			icon   : <PaymentIcon/>,
			onClick: () => {
				showModal( DeviceLoadingModal, {
					maxWidth: 'sm',
					onClose : ( event, reason ) => stayOpen( event, reason ),
				}, {
					invoice,
					title         : 'Edit On Clover',
					bodyTitle     : 'Editing in progress.',
					buttonText    : `Edit ${toLower( invoice.type )}`,
					secondaryTitle: `Edit the ${toLower( invoice.type )} on Clover`,
				} );
			},
		},
		!single && {
			name : t( 'common:view' ),
			icon : <FindInPageIcon/>,
			props: {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/invoices/${invoice.id}`,
			},
		},
		editable
		&& payable
		&& !noPay
		&& invoice.status === 'ACCOUNT'
		&& !invoice.standing
		&& invoice?.grandTotal > 0
		&& invoice?.paidTotal < invoice?.grandTotal
		&& {
			name   : t( 'common:pay' ),
			icon   : <PaymentIcon/>,
			onClick: () => showModal( PaymentDrawer, {
				variant: 'drawer',
				onClose: ( event, reason ) => stayOpen( event, reason ),
			}, { order: invoice } ),
		}, ( editable && invoice.standing && invoice.standingData || invoice.metadata?.originalCommerce ) && {
			name   : t( 'common:view-history' ),
			icon   : <PaymentIcon/>,
			onClick: () => showModal( HistoryCommerces, { variant: 'drawer' }, { data: invoice } ),
		},
		...invoiceActions( invoice, undefined, noPay ).slice( 1 ),
		editable && invoice.standing && !invoice.standingActive && {
			name       : t( 'common:active-standing' ),
			icon       : <EventRepeatIcon/>,
			buttonProps: { variant: 'contained', color: 'primary' },
			details    : t( 'common:scheduled-invoice' ),
			onClick    : () => showModal( RecurringModal, {
				maxWidth     : 'sm',
				fullPageModal: true,
			}, {
				formOrder: invoice,
			} ),
		},
		editable && invoice.standing && invoice.standingActive && {
			name       : t( 'common:deactivate-standing' ),
			buttonProps: { variant: 'contained', color: 'warning' },
			icon       : <EventRepeatIcon/>,
			details    : t( 'common:stop-scheduled-invoice' ),
			onClick    : async () => await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : InvoiceWrite,
				variables: {
					id    : invoice.id,
					method: 'Deactivated Standing',
					input : { standingActive: !invoice.standingActive },
				},
			} ),
		},
		editable && invoice.standing && invoice.standingData && {
			name   : 'Create New',
			details: 'Create the next recurring invoice manually',
			icon   : <AddCircleIcon/>,
			onClick: async () => {
				enqueueSnackbar( 'Please wait while the new invoice is being generated...', { variant: 'info' } );
				await generateRecurring( invoice.id );
				event.emit( 'reload.graphqlQuery', true );
			},
		},
		editable && !invoice.standing && qbconnect && {
			name   : t( 'common:sync-qb' ),
			endNode: !syncToQuickbooksSubscriptionIsValid && <UpgradeIcon permission='QB_SYNC'/>,
			icon   : (
				<StyledImage
					sx={{ height: 18, width: 18 }}
					src='/images/qb-icon.png'
					alt='Quickbooks'
				/>
			),
			onClick: async () => {
				if ( !syncToQuickbooksSubscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
				
				const qbTaxOption = company?.metadata?.qbTaxOption;
				const qbDefaultTax = qbTaxOption?.tax;
				const noQBTax = qbTaxOption?.option === 'No tax';
				
				if ( !invoice.client ) {
					enqueueSnackbar( t( 'common:add-client-before-sync' ), { variant: 'default' } );
					return;
				}
				if ( isEmpty( invoice.lineItems ) ) {
					enqueueSnackbar( t( 'common:add-line-item-before-sync' ), { variant: 'default' } );
					return;
				}
				if ( !isEmpty( invoice.lineItems?.map( ( lineItem ) => lineItem.prices?.find( ( price ) => price.value < 0 ) )
					.filter( Boolean ) ) && !invoice.prices?.find( ( price ) => price.value < 0 ) ) {
					enqueueSnackbar( t( 'common:add-discount-before-sync' ), { variant: 'info' } );
					return;
				}
				
				let syncPayments;
				if ( invoice.status === 'ACCOUNT' ) {
					syncPayments = await confirmDialog( {
						title  : t( 'common:sync-payments' ),
						message: t( 'common:sync-payments-msg' ),
					} );
					
				}
				if ( !invoice.client?.metadata?.exemptFromTax && !noQBTax && !qbDefaultTax ) {
					showModal( QbInvoiceTax, { maxWidth: 'sm' }, {
						invoice  : invoice,
						companyId: invoice.company.id,
						syncPayments,
					} );
					return;
				}
				const barId = enqueueSnackbar( t( 'common:qb-sync-inprogress' ), { variant: 'info', persist: true } );
				return await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manageQB/postInvoice`, {
					id     : invoice.id,
					company: invoice.company.id,
					prices : qbDefaultTax?.prices,
					syncPayments,
					staffId: staff?.id,
				} )
					.then( () => enqueueSnackbar( t( 'common:quickbook-sync-success' ), { variant: 'success' } ) )
					.catch( ( e ) => {
						throw new Error( e?.response?.data?.message || TEMP_HARDCODED_ERROR );
					} )
					.finally( () => closeSnackbar( barId ) );
			},
		},
		editable && !invoice.standing && squareConnected && single && {
			name: t( 'common:sync-square' ),
			icon: (
				<StyledImage
					sx={{ height: 18, width: 18 }}
					src='/images/square.svg'
					alt='Quickbooks'
				/>
			),
			onClick: async () => {
				if ( !invoice.client ) {
					enqueueSnackbar( t( 'common:add-client-before-sync' ), { variant: 'default' } );
					return;
				}
				if ( isEmpty( invoice.lineItems ) ) {
					enqueueSnackbar( t( 'common:add-line-item-before-sync' ), { variant: 'default' } );
					return;
				}
				if ( !isEmpty( invoice.lineItems?.map( ( lineItem ) => lineItem.prices?.find( ( price ) => price.value < 0 ) )
					.filter( Boolean ) ) && !invoice.prices?.find( ( price ) => price.value < 0 ) ) {
					enqueueSnackbar( t( 'common:add-discount-before-sync' ), { variant: 'info' } );
					return;
				}
				
				return;
			},
		},
		editable && !invoice.standing && invoice.externalValue && qbconnect && {
			name: t( 'common:import-qb' ),
			icon: (
				<StyledImage
					sx={{ height: 18, width: 18 }}
					src='/images/qb-icon.png'
					alt='Quickbooks'
				/>
			),
			onClick: async () => {
				const snackBarId = enqueueSnackbar( t( 'common:quickbook-sync-inprogress' ), {
					variant: 'info',
					persist: true,
				} );
				
				const { gatewaysRead } = await queryGraphQL<QueryGatewaysReadArgs>( {
					query: gql`
						query GatewaysRead_8e79($options: FilterOptions) {
							gatewaysRead(options: $options) {
								items {
									id
									externalId
								}
							}
						}
					`,
					variables: { options: { filter: { external: 'QUICKBOOKS', active: true } } },
				} );
				const qbGateway = gatewaysRead?.items?.[ 0 ] as { id: string, externalId: string };
				const qbInvoiceId = invoice.externalValue?.split( '-' )[ 1 ];
				if ( !qbInvoiceId ) throw new Error( `Invoice externalValue not found: ${invoice.externalValue}` );
				try {
					const { data } = await getInvoices( { gateway: qbGateway, ids: [ qbInvoiceId ] } );
					const invoice = data?.[ 0 ];
					const input = convertOrder( qbGateway, invoice );
					await mutateGraphQL<MutationQbInvoicesSyncArgs>( {
						mutation: gql`
							mutation QbInvoicesSync_2b46($inputs: [OrderValidator!], $method: String) {
								qbInvoicesSync(inputs: $inputs, method:$method)
							}
						`,
						variables: { method: 'Imported From QuickBooks', inputs: [ input ] },
					} );
				} catch ( e ) {
					e.message = t( 'common:quickbook-sync-error' );
					throw e;
				} finally {
					closeSnackbar( snackBarId );
				}
			},
		}, devices?.length && invoice.status !== 'PAID' && invoice.status !== 'ACCOUNT' && {
			name   : 'Pay on Device',
			icon   : <PointOfSaleIcon/>,
			endNode: !deviceSubscriptionIsValid && <UpgradeIcon permission='DEVICE_SUPPORT'/>,
			onClick: async () => {
				if ( !deviceSubscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
				return showModal( TerminalDevicePaymentModal, { maxWidth: 'sm' }, { invoice, devices } );
			},
		},
		!isCloverDevice && invoice.externalId && !invoice.standing && invoice.gateway?.external === 'CLOVER' && invoice.status !== 'PAID' && invoice.status !== 'ACCOUNT' && {
			name   : 'Pay on Clover',
			icon   : <PointOfSaleIcon/>,
			onClick: () => showModal( CloverDeviceSelectionModal, { maxWidth: 'xs' }, { invoice } ),
		},
		editable && payable && !removeSchedulePayment && invoice.grandTotal !== 0 && invoice.status !== 'CANCELLED' && invoice.status !== 'PAID' && !invoice.standing && {
			name   : t( 'common:schedule-payment' ),
			icon   : <EventRepeatIcon/>,
			onClick: () => showModal( SchedulePaymentsModal,
				{
					onClose      : ( event, reason ) => stayOpen( event, reason ),
					maxWidth     : 'sm',
					fullPageModal: true,
				},
				{
					id: invoice.id,
				} ),
		}, {
			name   : invoice.status === 'DRAFT' ? t( 'common:send-text' ) : t( 'common:resend-text' ),
			icon   : <SmsIcon/>,
			endNode: !textMessageSubscriptionIsValid && <UpgradeIcon permission='INVOICE_TEXT'/>,
			onClick: () => {
				if ( !textMessageSubscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
				return showModal( TextMessage, { maxWidth: 'xs' }, {
					order   : invoice,
					onSubmit: async () => {
						await mutateGraphQL<MutationInvoiceWriteArgs>( {
							mutation : InvoiceWrite,
							variables: {
								id    : invoice.id,
								method: 'Sent via Text',
								input : {
									sent    : true,
									metadata: {
										...invoice.metadata,
										sentDates: [ ...!isEmpty( invoice.metadata?.sentDates )
											? [ ...invoice.metadata.sentDates, new Date() ]
											: [ new Date() ] ],
									},
								},
							},
						} );
						await updateStock( invoice, staff.company, 'manualSendStock', enqueueSnackbar );
					},
				} );
			},
		},
		editable && !invoice.standing && {
			name   : t( 'common:sign-tag' ),
			icon   : <LocalOfferIcon/>,
			onClick: () => showModal( () => <SignAndTagModal data={invoice}/>, { maxWidth: 'xs' } ),
		}, {
			name   : 'Copy Invoice Link',
			icon   : <ContentCopyIcon/>,
			onClick: async () => {
				await navigator.clipboard.writeText( `${process.env.NEXT_PUBLIC_SITE_URL}/p/${invoice.id}/invoice` );
				enqueueSnackbar( t( 'common:link-copy-success' ), { variant: 'success' } );
			},
		},
		editable
		&& payable
		&& !noPay
		&& !invoice.standing
		&& invoice?.grandTotal > 0
		&& invoice?.paidTotal < invoice?.grandTotal
		&& {
			name   : 'Copy Payment Link',
			icon   : <PaymentIcon/>,
			onClick: async () => {
				if ( !invoice.companyLocation?.id ) {
					enqueueSnackbar( 'No company location to create payment link.', { variant: 'error' } );
					return;
				}
				const cloverLocation = invoice.companyLocation?.gateway?.external === 'CLOVER';
				
				try {
					const cloverOrder = cloverLocation && !invoice.externalId && await SyncToClover( invoice );
					if ( cloverOrder?.id || cloverOrder?.commerce || invoice.externalId || !cloverLocation ) {
						const link = generatePaymentLink( {
							locationId   : invoice.companyLocation?.id,
							amount       : invoice.grandTotal - ( invoice.paidTotal || 0 ),
							invoiceId    : invoice.id,
							invoiceNumber: invoice.metadata?.customNumber || invoice.number,
							clientId     : invoice.client?.id,
						} );
						if ( link ) {
							if ( !document.hasFocus() ) {
								window.focus();
								await new Promise( ( resolve ) => setTimeout( resolve, 300 ) ); // Give the browser some time to focus
							}
							
							if ( document.hasFocus() ) {
								await navigator.clipboard.writeText( link );
								enqueueSnackbar( t( 'common:link-copy-success' ), { variant: 'success' } );
							}
						} else {
							enqueueSnackbar( 'Payment link could not be created', { variant: 'error' } );
						}
					}
				} catch ( e ) {
					console.error( 'Copy link failed', e );
					enqueueSnackbar( 'Payment link could not be copied', { variant: 'error' } );
				}
				
			},
		}, !isMobile && !isCloverDevice && {
			name : t( 'common:client-view' ),
			icon : <FindInPageIcon/>,
			props: {
				component: PageLinkComponent,
				href     : `/p/${invoice.id}/invoice`,
				target   : '_blank',
			},
		},
		editable && invoice.type !== 'ACCOUNT' && invoice.type !== 'STATEMENT' && {
			name   : t( 'common:make-copy' ),
			icon   : <CopyAllIcon/>,
			onClick: async () => {
				try {
					const invoiceWriteId = await makeInvoiceCopy( invoice, staff );
					await router.push( `/dashboard/commerce/invoices/${invoiceWriteId}/edit` );
				} catch ( e ) {
					throw e;
				}
			},
		},
		editable && navigator.share && {
			name   : t( 'common:share' ),
			icon   : <ShareIcon/>,
			onClick: () => navigator.share( { url: `${process.env.NEXT_PUBLIC_SITE_URL}/p/${invoice.id}/invoice` } ),
		},
		invoice.type !== 'ACCOUNT' && invoice.type !== 'STATEMENT' && {
			name   : invoice.metadata?.stock ? t( 'common:put-back' ) : t( 'commerce:update-stock' ),
			details: t( 'commerce:confirm-stock' ),
			icon   : <ArchiveIcon/>,
			onClick: async () => {
				const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
					query    : LineItemsFetch,
					variables: { options: { filter: { order: invoice.id } } },
				} );
				if ( isEmpty( lineItemsRead?.items ) ) {
					enqueueSnackbar( t( 'commerce:empty-stock' ), { variant: 'default' } );
					return;
				} else {
					return showModal( CommerceItemsUpdating, {
						maxWidth: 'lg',
						onClose : ( event, reason ) => stayOpen( event, reason ),
					}, { commerce: invoice } );
				}
			},
		},
		invoice.externalId && !invoice.payments?.find( ( payment ) => Boolean( payment.cardConnectId ) || toLower( payment.type ) === 'bank transfer' || toLower( payment.type ) === 'us_bank_account' ) && {
			name   : t( 'common:check-updates' ),
			details: t( 'common:duplicate-after-sync' ),
			icon   : <SyncProblemIcon/>,
			onClick: async () => {
				try {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
						id         : invoice.id,
						reverseSync: true,
						staffId    : staff?.id,
					} );
					enqueueSnackbar( t( 'common:sync-clover-success' ), { variant: 'success' } );
				} catch ( e ) {
					console.error( e );
					throw new Error( TEMP_HARDCODED_ERROR );
				}
			},
		}, cardConnectPayment && {
			name   : t( 'common:check-payment-status' ),
			icon   : <SyncProblemIcon/>,
			onClick: async () => {
				await getTransactionStatus( {
					cardConnectId: cardConnectPayment.cardConnectId,
					gateway      : invoice.gateway,
					amount       : cardConnectPayment.amount,
				} );
			},
		},
		...editable ? isMobile
			? RecurringActions( invoice, staff, router )
			: !isEmpty( RecurringActions( invoice, staff, router ) ) ? [ {
				name       : t( 'commerce:recurring-actions' ),
				icon       : <ChangeCircleIcon/>,
				nestedItems: RecurringActions( invoice, staff, router ).filter( Boolean ),
			} ] : [] : [],
		...editable && invoice.status !== 'PAID' && !invoice.standing && invoice.status !== 'PARTIALLY_PAID' && invoice.type !== 'ACCOUNT' && invoice.type !== 'STATEMENT'
			? isMobile
				? ConvertActions
				: [ {
					name       : t( 'commerce:convert-to' ),
					icon       : <TransformRoundedIcon/>,
					nestedItems: ConvertActions.filter( Boolean ),
				} ]
			: [],
		...isMobile && !invoice.standing ? MarkStatusActions( invoice ) || []
			: !invoice.standing ? [ {
				name       : t( 'commerce:change-status' ),
				icon       : <ChangeCircleIcon/>,
				nestedItems: MarkStatusActions( invoice ).filter( Boolean ),
			} ] : [],
		...isMobile
			? HouseAccountActions
			: !isEmpty( HouseAccountActions ) ? [ {
				name       : t( 'commerce:house-account-actions' ),
				icon       : <OtherHousesRoundedIcon/>,
				nestedItems: HouseAccountActions.filter( Boolean ),
			} ] : [],
		...isMobile
			? MoreActions( invoice, single )
			: [ {
				name       : t( 'common:more-action' ),
				icon       : <MoreVertIcon/>,
				nestedItems: MoreActions( invoice, single ).filter( Boolean ),
			} ] ].filter( Boolean ) as ActionProps[];
}
