import StyledImage from '@/components/styledImage';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Divider, ListItemButton, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { Order } from '../types/schema';
import { QBTaxesButton } from './qbInvoiceTax';

export default function SyncWithHouseAccount( {
	onSubmit,
	invoice,
	selectQBTax,
	isView,
	onlyHouseAccountOptions = false,
}: {
	onSubmit: ( action: string, qbTax?: any ) => void,
	invoice: Order,
	selectQBTax?: boolean,
	isView?: boolean,
	onlyHouseAccountOptions?: boolean
} ) {
	const { closeModal } = useModalControls();
	const [ tax, setTax ] = useState( null );
	const [ actionType, setActionType ] = useState( '' );
	
	const amount = invoice.grandTotal - ( invoice.paidTotal || 0 );
	
	return (
		<ResponsiveModalContainer
			title='Your client has a house account'
			secondaryTitle='Should it be a house account order instead?'
			saveButtonText={isView ? 'View' : 'Sync & view'}
			saveButtonProps={{
				disabled : !actionType,
				startIcon: isView ? undefined : (
					<StyledImage
						alt='clover-pos-image'
						src='/images/clover-icon.png'
						width='20px'
						height='20px'
					/>
				),
			}}
			onClose={() => {
				setActionType( '' );
				setTax( null );
				closeModal();
			}}
			onSave={() => {
				onSubmit( actionType, tax );
				closeModal();
			}}>
			<Stack
				spacing={1}
				sx={{
					'.MuiListItemButton-root': {
						p: 2,
					},
				}}>
				{!onlyHouseAccountOptions && (
					<ListItemButton
						disableGutters
						sx={{
							borderRadius: 3,
							border      : 4,
							borderColor : actionType === 'Sync' ? 'primary.main' : 'divider',
						}}
						onClick={() => setActionType( 'Sync' )}>
						<Stack spacing={.5}>
							<Typography variant='h5'>Individual Invoice</Typography>
							<Typography>Do not charge to the house account; my client will pay individually.</Typography>
						</Stack>
					</ListItemButton>
				)}
				{!onlyHouseAccountOptions && <Divider sx={{ my: '20px !important' }}/>}
				<Typography variant='h6'>
					House Account
				</Typography>
				{!isView && (
					<ListItemButton
						disableGutters
						disabled={amount === 0}
						sx={{
							borderRadius: 3,
							border      : 4,
							borderColor : actionType === 'Pay with Invoiss' ? 'primary.main' : 'divider',
						}}
						onClick={() => setActionType( 'Pay with Invoiss' )}>
						<Stack spacing={.5}>
							<Typography variant='h5'>Charge to House account</Typography>
							<Typography>Mark it as paid on Clover (Recommended)</Typography>
						</Stack>
					</ListItemButton>
				)}
				<ListItemButton
					disableGutters
					disabled={amount === 0}
					sx={{
						borderRadius: 3,
						border      : 4,
						borderColor : actionType === 'Pay without Invoiss'
							? 'primary.main'
							: 'divider',
					}}
					onClick={() => setActionType( 'Pay without Invoiss' )}>
					<Stack spacing={.5}>
						<Typography variant='h5'>Charge to House account</Typography>
						{!isView && <Typography>Leave it open on Clover</Typography>}
					</Stack>
				</ListItemButton>
				{!isView && selectQBTax && !invoice.client?.metadata?.exemptFromTax && (
					<Fragment>
						<Divider sx={{ my: '20px !important' }}/>
						<Typography variant='h6'>
							Quickbooks Taxes
						</Typography>
						<QBTaxesButton tax={tax} setTax={( tax ) => setTax( tax )}/>
					</Fragment>
				)}
			</Stack>
		</ResponsiveModalContainer>
	);
}
