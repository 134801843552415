import { queryGraphQL } from '@/data/apollo';
import { GatewaysRead } from '@/data/gateway.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { GatewayBase } from '@/types/schema';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import process from 'process';

export default function useGetTransactionStatus() {
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	
	return async ( { cardConnectId, gateway, amount }: {
		cardConnectId: string,
		gateway: GatewayBase,
		amount: number
	} ) => {
		try {
			if ( cardConnectId ) {
				let cardConnectGateway = gateway;
				if ( cardConnectGateway?.external !== 'CARD_CONNECT' ) {
					const { gatewaysRead } = await queryGraphQL( {
						query    : GatewaysRead,
						variables: {
							options: {
								limit : 1,
								filter: { external: 'CARD_CONNECT', active: true, company: staff.company?.id },
							},
						},
					} );
					const gateway = gatewaysRead?.items[ 0 ];
					if ( !!isEmpty( gateway ) ) {
						enqueueSnackbar( 'Could not find gateway', { variant: 'default' } );
						return;
					}
					cardConnectGateway = gateway;
				}
				
				const postData = {
					cardConnectPaymentId: cardConnectId,
					gatewayId           : cardConnectGateway.id,
					companyId           : staff.company.id,
					amount              : amount,
				};
				const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/cardConnect/merchant/getAchStatus`, postData );
				if ( data.status ) {
					enqueueSnackbar( data.status, { variant: 'info' } );
				}
			}
		} catch ( e ) {
			console.log( e );
			enqueueSnackbar( e?.response?.data || 'Error getting transaction status', { variant: 'error' } );
		}
	};
}
