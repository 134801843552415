import { queryGraphQL } from '@/data/apollo';
import {
	VerifyHouseAccountInvoicesModal,
} from '@/pages/dashboard/management/houseAccounts/form/modals/verifyHouseAccountInvoices';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal, useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Divider, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MonthlyHouseAccountInvoicesRead } from '../../../../../../data/commerce/invoice.graphql';
import { Client, HouseAccount, Order, QueryMonthlyHouseAccountInvoicesReadArgs } from '../../../../../../types/schema';
import { getBrowserTimezone } from '../../../../../../utils/timezone';
import { useLocations } from '../../../../../formSelects/locationSelect';
import { generateHAInvoice } from '../../utils';

export default function GenerateInvoice( {
	houseAccount,
	onGenerate,
	month,
	allMonths,
	startOfWeek,
	endOfWeek,
	allWeeks,
	selectedInvoices,
}: {
	houseAccount: HouseAccount,
	onGenerate: ( invoice?: Order ) => void,
	month?: string,
	allMonths?: boolean,
	startOfWeek?: Date,
	endOfWeek?: Date,
	allWeeks?: boolean,
	selectedInvoices?: Order[]
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const { showModal } = useModal();
	const { closeModal } = useModalControls();
	const { t } = useTranslation();
	const [ locations ] = useLocations();
	
	const timezone = getBrowserTimezone();
	const [ locationId, setLocationId ] = useState( locations?.[ 0 ]?.id || '' );
	const serviceDate = selectedInvoices?.find( ( invoice ) => invoice.serviceDate )?.serviceDate;
	
	const invoiceMonth = endOfWeek
		? utcToZonedTime( endOfWeek, timezone ).toISOString()
		: utcToZonedTime( month || serviceDate ? new Date( month || serviceDate ) : new Date(), timezone ).toISOString();
	
	const generateInvoice = async ( monthlyInvoices: Order[] ) => {
		enqueueSnackbar( t( 'management:invoice-generating-snackbar' ), { variant: 'info' } );
		const location = locations.find( ( location ) => location.id === locationId );
		
		const invoice = await generateHAInvoice( monthlyInvoices, staff, houseAccount, invoiceMonth, houseAccount?.client as Client || monthlyInvoices?.[ 0 ]?.client as Client, location );
		enqueueSnackbar( 'Invoice is generated successfully.', { variant: 'success' } );
		
		onGenerate( invoice );
	};
	
	return (
		<ResponsiveModalContainer
			closeOnSave={false}
			title={t( 'management:generate-an-invoice' )}
			saveButtonText={t( 'common:generate' )}
			saveButtonProps={{ disabled: !locationId }}
			onSave={async () => {
				if ( !locationId ) {
					enqueueSnackbar( 'Location has not been selected.', { variant: 'info' } );
					return;
				}
				
				let monthlyInvoices: Order[] = selectedInvoices;
				
				if ( isEmpty( monthlyInvoices ) ) {
					const { monthlyHouseAccountInvoicesRead } = await queryGraphQL<QueryMonthlyHouseAccountInvoicesReadArgs>( {
						query    : MonthlyHouseAccountInvoicesRead,
						variables: {
							month    : invoiceMonth,
							allMonths: !isEmpty( selectedInvoices ) || allMonths || allWeeks,
							HAId     : houseAccount.id,
							timeZone : timezone,
							startOfWeek,
							endOfWeek,
						},
					} );
					monthlyInvoices = monthlyHouseAccountInvoicesRead?.items;
					if ( isEmpty( monthlyInvoices ) ) throw 'No invoices were found.';
				}
				
				const invoicesWithMergedTo = monthlyInvoices.filter( ( invoice ) => invoice.metadata?.mergedTo );
				if ( !isEmpty( invoicesWithMergedTo ) ) {
					closeModal();
					showModal( VerifyHouseAccountInvoicesModal, { maxWidth: 'sm' }, {
						invoicesWithMergedTo,
						onSubmit: async () => await generateInvoice( monthlyInvoices ),
					} );
				} else {
					await generateInvoice( monthlyInvoices );
					closeModal();
				}
				
			}}>
			<Grid container spacing={2}>
				<Grid item sm={4}>
					{!isEmpty( locations )
						? locations.length > 1
							? (
								<Stack direction='column' sx={{ mt: 1 }} spacing={1}>
									<InputLabel>Select Location To Pay</InputLabel>
									<Select
										autoFocus
										sx={{ width: 300 }}
										value={locationId || ''}
										onChange={( e ) => setLocationId( e.target.value )}>
										{locations.map( ( location, i ) => (
											<MenuItem key={i} value={location.id}>
												{location.name || location.address.line1}
											</MenuItem>
										) )}
									</Select>
								</Stack>
							)
							: <Typography>For: {locations[ 0 ].name || locations[ 0 ].address.line1}</Typography>
						: <Typography>No locations available. Please add locations for this team member.</Typography>}
				</Grid>
				<Typography sx={{ color: 'warning.main', p: 2 }}>
					<Divider sx={{ my: 1 }}/>
					{t( 'management:invoice-will-be-generated-from' )}
				</Typography>
			</Grid>
		</ResponsiveModalContainer>
	);
	
}
