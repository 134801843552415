import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Order } from '@/types/schema';
import { Stack, Typography } from '@mui/material';

export function VerifyHouseAccountInvoicesModal( { invoicesWithMergedTo, type = 'Generate', onSubmit }: {
	invoicesWithMergedTo: Order[],
	type?: 'Pay' | 'Generate',
	onSubmit: () => void
} ) {
	
	const { closeModal } = useModalControls();
	
	return (
		<ResponsiveModalContainer
			title={type === 'Pay' ? 'Invoices Payment' : 'Invoices Recreation.'}
			saveButtonText={`${type} anyway`}
			onSave={() => onSubmit()}
			onClose={closeModal}>
			<Stack spacing={1} justifyContent='center'>
				<Typography>The following orders have already been invoiced:</Typography>
				{invoicesWithMergedTo.map( ( invoice ) => (
					<Stack key={invoice.id} spacing={1}>
						<Typography variant='h6'>#{invoice.metadata?.customNumber || invoice.externalId || invoice.number}</Typography>
					</Stack>
				) )}
			</Stack>
		</ResponsiveModalContainer>
	);
}

